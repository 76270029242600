<template>
    <div class="cover"></div>
</template>

<script>
export default {
    name: 'cover',
}
</script>

<style scoped lang="scss">
.cover {
    background: #fff url(/images/header-background.jpg) repeat-x center top;
    width: 100%;
    height: 199px;
    @media (max-width: 979px) {
        display: none;
    }
}
</style>
